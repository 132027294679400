<template>
  <div>
    <!-- Top Navigation -->
    <div class="ml-4 pl-3">
      <back-button/>
    </div>
    <div class="container-fluid d-flex justify-content-between mt-4 mb-4 row">
      <h1 class="ml-3 d-inline-block mt-2"> View {{ dynamicSectionDetail.dynamic_section ? dynamicSectionDetail.dynamic_section.name : 'Section' }} Detail</h1>
      <router-link
        class="router-link pr-2"
        :to="{name: 'EditDynamicContentSectionItem' , params: { itemId: dynamicSectionItemId ,
        sectionId:dynamicSectionDetail.dynamic_section.id, sectionName: dynamicSectionDetail.dynamic_section.name}}">
        <base-button type="secondary" class="pt-2 pb-2 font-weight-normal custom-button">
          Edit
        </base-button>
      </router-link>
    </div>
    <div class="mt-1 row px-3 mx-0">
      <div class="col-6 content-section-card-width">
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2"
              id="basic-info-card" v-loading="loaders.card">
          <div slot="header" class="d-flex ">
            <img class="rounded image-fit" v-if="dynamicSectionDetail.picture" :src="dynamicSectionDetail.picture" width="124" height="114" alt=""/>
            <div class="content-section-header-width">
              <div class="row justify-content-between ml-2 mr-0">
                <div class="d-inline-block content-section-heading-width">
                  <h1 class="section-item-name">{{ dynamicSectionDetail.name }}</h1>
                </div>
                <div class="d-inline-block">
                  <p class="section-item-status px-3 px-1 py-1 mt-1"
                     :class="dynamicSectionDetail.status === 0 ? 'inactive' : 'active'">
                    {{ getStatus(dynamicSectionDetail.status) }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <h2 class="mt-3 pl-4 section-item-detail-heading">BASIC INFO</h2>
          <div class="row pt-2 px-4">
            <div class="col-6">
              <p class="section-item-detail-text">
                {{ dynamicSectionDetail.dynamic_section ? dynamicSectionDetail.dynamic_section.name : 'Section' }} Category
              </p>
              <p class="section-item-detail-text">
                {{ dynamicSectionDetail.dynamic_section ? dynamicSectionDetail.dynamic_section.name : 'Section' }} Sub Category
              </p>
            </div>
            <div class="col-6">
              <p class="black_p ellipsis-overflow-text" v-if="dynamicSectionDetail.dynamic_section_category">
                {{ dynamicSectionDetail.dynamic_section_category.name }}
              </p>
              <p class="black_p ellipsis-overflow-text" v-if="dynamicSectionDetail.dynamic_section_sub_category">
                {{ dynamicSectionDetail.dynamic_section_sub_category.name }}
              </p>
            </div>
          </div>
        </card>
      </div>
      <div class="col-6 content-section-card-width">
        <card class="overflow-auto no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2" v-loading="loaders.card">
          <div class="px-4">
            <h2 class="mt-2 content-detail-heading">Content</h2>
            <p class="mb-2 overflow-auto dynamic-section-content-max-height" v-html="dynamicSectionDetail.content"></p>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton";

export default {
  name: "ShowDynamicContentSection",
  components: {
    BackButton,
  },
  data() {
    return {
      loaders: {
        card: false,
      },
      dynamicSectionItemId: this.$route.params.id ?? 0,
      dynamicSectionDetail: {
        name: '',
        content: '',
        status: '',
        dynamic_section: '',
        dynamic_section_id: '',
        dynamic_section_category: '',
        dynamic_section_sub_category: '',
      },
    }
  },
  mounted() {
    this.getDynamicSectionListItem()
  },
  methods: {
    /**
     * Fetch item of this dynamic content section
     */
    getDynamicSectionListItem() {
      let vm = this
      vm.loaders.card = true
      const payload = {
        dynamicSectionId: vm.dynamicSectionItemId
      }
      vm.$store.dispatch('dynamicContentSectionModule/_getDynamicSectionListItem', payload)
        .then(response => {
          vm.dynamicSectionDetail = response.data.data
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Dynamic Content Section Item',
            message: message
          });
        })
        .finally(() => {
          vm.loaders.card = false
        })
    },

    /**
     * Get status as a string
     * @param status
     * @returns {string}
     */
    getStatus(status) {
      return status === 0 ? 'Inactive' : 'Active'
    },

  },
}
</script>

<style scoped>

.active {
  background: #94B527 0 0 no-repeat padding-box;
}

.inactive {
  background: #BB2255 0 0 no-repeat padding-box;
}

.content-detail-heading {
  text-align: left;
  font: normal normal bold 16px/22px Open Sans;
  letter-spacing: 0;
  color: #172B4D;
  text-transform: uppercase;
  opacity: 1;
}

.section-item-detail-text {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0;
  color: #8898AA;
  opacity: 1;
}

.dynamic-section-content-max-height{
  max-height: 400px;
}

.image-fit{
  object-fit: cover;
}

.section-item-name {
  text-align: left;
  font: normal normal 600 25px/34px Open Sans;
  letter-spacing: 0;
  color: #32325D;
  opacity: 1;
}

.section-item-status {
  box-shadow: 0px 3px 2px #E9ECEF0D;
  border-radius: 20px;
  opacity: 1;
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0.48px;
  color: #FFFFFF;
}

.section-item-detail-heading {
  text-align: left;
  font: normal normal bold 16px/22px Open Sans;
  letter-spacing: 0;
  color: #172B4D;
  text-transform: uppercase;
  opacity: 1;
}
</style>
