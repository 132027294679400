<template>
  <div class="back-button-container">
    <div class="router-link" @click="$router.back()">
      <div class="d-inline-block">
        <img class="d-inline-block mt--1" src="/img/back_arrow.svg" alt="">
        <h3 class="text-white mb-0 ml-2 d-inline-block">Back</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BackButton.vue",
  props: ['navigateTo', 'params'],
}
</script>

<style scoped>
.back-button-container {
  position: fixed;
  top: 28px;
  z-index: 1049;
}

.router-link {
  cursor: pointer;
}
</style>
